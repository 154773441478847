import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import pmt from 'utils/pmt'
import Content from 'components/content'
import Section from 'components/section'
import { GatsbyImage } from 'gatsby-plugin-image'
import AlternatingGrid from 'components/alternating-grid'
import Disclaimer from 'components/landing-page/disclaimer'
import Button from 'components/button/button'
import { useEffectOnce } from 'hooks/use-effect-once'

const breadcrumbsSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': [
    {
      '@type': 'ListItem',
      'position': 1,
      'name': 'Hutson Inc',
      'item': 'https://www.hutsoninc.com/',
    },
    {
      '@type': 'ListItem',
      'position': 2,
      'name': 'Lawn & Garden Equipment',
      'item': 'https://www.hutsoninc.com/lawn-garden/',
    },
    {
      '@type': 'ListItem',
      'position': 3,
      'name': 'Compact Tractors',
      'item': 'https://www.hutsoninc.com/lawn-garden/compact-tractors/',
    },
    {
      '@type': 'ListItem',
      'position': 4,
      'name': 'Hutson Tractor Packages',
      'item': 'https://www.hutsoninc.com/lawn-garden/compact-tractors/hutson-tractor-packages/',
    },
  ],
})

const expirationDate = new Date('2024-10-25T00:00:00-06:00')
const expired = Date.now() > expirationDate
const expirationDateString = 'October 25, 2024'

const showTrailerSection = false
// const trailer16Payment = Math.ceil(2000 / financingTerm)
const trailerPrice = 4500
const trailerFinancingNumberPayments = 84
const trailerFinancingInterestRate = 0
const trailerPayment = Math.ceil(
  pmt(trailerFinancingInterestRate, trailerFinancingNumberPayments, trailerPrice)
)

const tractorPackagesData = [
  {
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/maintainer/',
    id: 'maintainer-package',
    imageId: 'maintainerImage',
    title: 'Maintainer',
    subtitle: '1025R Tractor Package',
    includes: [
      '1025R 25 HP tractor',
      '120R loader',
      '60-in AutoConnect deck',
      '48-in Frontier box blade',
    ],
    price: 26099,
    financingInterestRate: 0,
    financingNumberPayments: 84,
  },
  {
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/groundbreaker/',
    id: 'groundbreaker-package',
    imageId: 'groundbreakerImage',
    title: 'Groundbreaker',
    subtitle: '1025R Tractor Package',
    includes: [
      '1025R 25 HP tractor',
      '120R loader',
      '260B backhoe',
      '36-in Frontier pallet forks',
      '49-in Frontier rotary tiller',
    ],
    price: 32895,
    financingInterestRate: 0,
    financingNumberPayments: 84,
  },
  {
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/snow-prepper/',
    id: 'snow-prepper-package',
    imageId: 'snowPrepperImage',
    title: 'Snow Prepper',
    subtitle: '1025R Tractor Package',
    includes: [
      '1025R 25 HP tractor',
      'Enclosed cab',
      '120R loader',
      '60-in Frontier snow push',
      '60-in Frontier rear blade',
    ],
    price: 31225,
    financingInterestRate: 0,
    financingNumberPayments: 84,
  },
  {
    slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/gardener/',
    id: 'gardener-package',
    imageId: 'gardenerImage',
    title: 'Gardener',
    subtitle: '3025E Tractor Package',
    includes: [
      '3025E 25 HP tractor',
      '300E loader',
      '42-in Frontier pallet forks',
      '62-in Frontier rotary tiller',
    ],
    price: 30345,
    financingInterestRate: 0,
    financingNumberPayments: 84,
  },
  // {
  //   slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/farm-hand/',
  //   id: 'farm-hand-package',
  //   imageId: 'farmHandImage',
  //   title: 'Farm Hand',
  //   subtitle: '3032E Tractor Package',
  //   includes: [
  //     '3032E 32 HP tractor',
  //     '300E loader',
  //     '54-in Frontier mechanical grapple',
  //     '60-in Frontier rotary cutter',
  //     '60-in Frontier box blade',
  //   ],
  //   price: 32299,
  //   financingInterestRate: 0,
  //   financingNumberPayments: 84,
  // },
  // {
  //   slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/turf-tamer/',
  //   id: 'turf-tamer-package',
  //   imageId: 'turfTamerImage',
  //   title: 'Turf Tamer',
  //   subtitle: '2032R Tractor Package',
  //   includes: [
  //     '2032R 32 HP tractor',
  //     '220R loader',
  //     '60-in AutoConnect deck',
  //     '60-in Frontier core aerator',
  //     'Frontier 3-point broadcast spreader',
  //   ],
  //   price: 38999,
  //   financingInterestRate: 0,
  //   financingNumberPayments: 84,
  // },
  // {
  //     slug: '/lawn-garden/compact-tractors/hutson-tractor-packages/pathfinder/',
  //     id: 'pathfinder-package',
  //     imageId: 'pathfinderImage',
  //     title: 'Pathfinder',
  //     subtitle: '4044M Tractor Package',
  //     includes: ['4044M 44 HP tractor', '400E loader', 'AP12F pallet forks', 'RC2072 72” rotary cutter'],
  //     price: 30200,
  //     financingInterestRate: 0,
  //     financingNumberPayments: 84,
  //     price: 30200,
  // },
].map(pkg => {
  return {
    ...pkg,
    payment: pkg.price
      ? Math.ceil(pmt(pkg.financingInterestRate, pkg.financingNumberPayments, pkg.price))
      : null,
  }
})

const HutsonTractorPackagesPage = ({ data }) => {
  const [tractorPackages, setTractorPackages] = useState([])

  useEffectOnce(() => {
    const combinedData = tractorPackagesData.map(obj => {
      obj.image = data[obj.imageId]
      return obj
    })
    setTractorPackages(combinedData)
  })

  return (
    <Layout>
      <Helmet>
        <title>Hutson Tractor Packages | Hutson Inc</title>
        <meta name='description' content='' />
        <script type='application/ld+json'>{breadcrumbsSchema}</script>
      </Helmet>

      <Hero>
        <HeroImageContainer>
          <HeroText>
            <Title>Hutson Tractor Packages</Title>
            <Description>
              Hit the ground running with Hutson's John Deere compact utility tractor packages.
              We've built these packages with up-front pricing so you can find the right fit for you
              and get right to work. No hassle or haggle necessary.
            </Description>
          </HeroText>
          <HeroOverlay />
          <HeroImage
            image={data.heroImage.childImageSharp.gatsbyImageData}
            alt=''
            objectFit='cover'
            objectPosition='35% 50%'
          />
        </HeroImageContainer>
      </Hero>

      <Content>
        <Grid>
          {tractorPackages.map(pkg => (
            <PackageContainer key={pkg.id}>
              <PackageImage
                image={pkg.image.childImageSharp.gatsbyImageData}
                alt={`Hutson ${pkg.title} Tractor Package`}
              />
              <PackageDetails>
                <PackageTitle>{pkg.title}</PackageTitle>
                <PackageSubtitle>{pkg.subtitle}</PackageSubtitle>
                <PackageIncludesHeader>Package Includes:</PackageIncludesHeader>
                <PackageIncludes>
                  {pkg.includes.map(inc => (
                    <li key={`${pkg.id}-${inc}`}>{inc}</li>
                  ))}
                </PackageIncludes>
                <FinancingContainer>
                  {!expired && pkg.payment ? (
                    <Financing>
                      <FinancingAmount>
                        {pkg.payment.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                        })}
                      </FinancingAmount>
                      /month
                      <FinancingTerm>
                        at{' '}
                        {pkg.financingInterestRate === 0
                          ? '0'
                          : (pkg.financingInterestRate * 100).toFixed(2)}
                        % for {pkg.financingNumberPayments} months with no money down
                      </FinancingTerm>
                    </Financing>
                  ) : (
                    <Financing>
                      <strong>See dealer for pricing and availability</strong>
                    </Financing>
                  )}
                </FinancingContainer>
                {pkg.outOfStock ? (
                  <OutOfStockButton color='gray'>Out of stock</OutOfStockButton>
                ) : (
                  <Button as={PackageLink} to={pkg.slug}>
                    View the {pkg.title} Package
                  </Button>
                )}
              </PackageDetails>
            </PackageContainer>
          ))}
        </Grid>

        {!expired && showTrailerSection ? (
          <Section>
            <TrailerDetailsTitle>Make it a trailer package</TrailerDetailsTitle>
            <TrailerDetailsSubtitle>
              Bring your workhorse home on a new tandem axle trailer
            </TrailerDetailsSubtitle>
            <AlternatingGrid
              direction='ltr'
              ratio='2/3'
              align='bottom'
              content={[
                <TrailerDetails>
                  <TrailerDetailsOptions>
                    <TrailerDetailsOption>
                      <h4>Add a 18' trailer</h4>
                      <TrailerFinancingContainer>
                        <Financing>
                          {/* <strong>See dealer for pricing</strong> */}
                          <FinancingAmount>
                            {trailerPayment.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                            })}
                          </FinancingAmount>
                          /month
                          <FinancingTerm>
                            at {(trailerFinancingInterestRate * 100).toFixed(2)}% for{' '}
                            {trailerFinancingNumberPayments} months with no money down
                          </FinancingTerm>
                        </Financing>
                      </TrailerFinancingContainer>
                    </TrailerDetailsOption>
                    {/* <TrailerDetailsOption>
                    <h4>Add a 18' trailer</h4>
                    <TrailerFinancingContainer>
                      <Financing>
                        <strong>See dealer for pricing</strong>
                        <FinancingAmount>
                          {trailer18Payment.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                          })}
                        </FinancingAmount>
                        /month
                        <FinancingTerm>at 0% for {financingTerm} months with no money down</FinancingTerm>
                      </Financing>
                    </TrailerFinancingContainer>
                  </TrailerDetailsOption> */}
                  </TrailerDetailsOptions>
                </TrailerDetails>,
                <TrailerImg
                  image={data.trailerImage.childImageSharp.gatsbyImageData}
                  alt='Tandem axle trailer'
                />,
              ]}
            />
          </Section>
        ) : null}

        <Section>
          <Disclaimer>
            {!expired ? `Offers valid through ${expirationDateString}. ` : ''}Prices and
            availability may vary. Taxes not included. Images for illustration purposes only.
            Financing subject to approved credit by John Deere Financial. Down payment may be
            required to receive financing. Taxes, freight, set up and delivery charges could
            increase the monthly payment. Restrictions may apply outside of Hutson’s area of
            responsibility. Terms and conditions may apply. See dealer for details.
          </Disclaimer>
        </Section>
      </Content>

      <FooterModule>
        <FooterModuleContent>
          <FooterModuleText>
            <FooterModuleTitle>Couldn't find the one?</FooterModuleTitle>
            <FooterModuleSubtitle>
              We can give you a custom quote on the tractor of your choice with the implements you
              need.
            </FooterModuleSubtitle>
            <Button as={FooterModuleLink} to='/lawn-garden/compact-tractors/'>
              Find the right tractor for you
            </Button>
          </FooterModuleText>
        </FooterModuleContent>
        <FooterModuleOverlay />
        <FooterModuleBackground>
          <GatsbyImage
            image={data.moreHorsepowerImage.childImageSharp.gatsbyImageData}
            objectFit='cover'
            objectPosition='30% 50%'
            alt=''
            style={{ width: '100%', height: '100%' }}
          />
        </FooterModuleBackground>
      </FooterModule>
    </Layout>
  )
}

const Hero = styled.div`
  height: 500px;
  overflow: hidden;
  position: relative;
`

const HeroImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const HeroOverlay = styled.div`
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media (min-width: 600px) {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
  }
`

const HeroText = styled(Content)`
  bottom: 50px;
  color: #fff;
  left: 50%;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;

  @media (min-width: 600px) {
    bottom: auto;
    left: auto;
    right: 25px;
    top: 60%;
    transform: translateY(-50%);
    width: 50%;
  }
`

const HeroImage = styled(GatsbyImage)`
  min-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
`

const Title = styled.h1`
  color: #fff;
  font-size: 2.5em;
  font-weight: 700;
  margin: 0;

  @media (min-width: 600px) {
    font-size: 3em;
  }
`

const Description = styled.p`
  color: #efefef;
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  max-width: 800px;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 1.25em;
    font-weight: 400;
  }
`

const Grid = styled.div`
  ${clearfix}
`

const PackageContainer = styled.div`
  @media (min-width: 800px) {
    ${column('1/2')}
  }
`

const PackageImage = styled(GatsbyImage)`
  width: 100%;
`

const PackageDetails = styled.div`
  margin: 10px 0 20px;

  @media (min-width: 800px) {
    padding: 0 20px;
  }
`

const PackageTitle = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0;
  text-transform: none;
`

const PackageSubtitle = styled.h3`
  color: #111;
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0 0 10px;
  text-transform: none;
`

const PackageIncludesHeader = styled.span`
  color: #222;
  font-weight: 600;
  font-size: 1.05em;
`

const PackageIncludes = styled.ul`
  color: #222;
  column-count: 2;
  column-width: 150px;

  li {
    margin: 0;
    padding: 0;
  }
`

const FinancingContainer = styled.div`
  margin: 10px 0 20px;
`

const Financing = styled.span`
  color: #333;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1;
`

const FinancingAmount = styled.span`
  color: #000;
  font-size: 2em;
  font-weight: 700;
`

const FinancingTerm = styled.span`
  color: #676767;
  display: block;
  font-size: 0.6em;
  font-style: italic;
`

const OutOfStockButton = styled(Button)`
  display: inline-block;
  text-decoration: none;
`

const PackageLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

const TrailerImg = styled(GatsbyImage)`
  margin-top: 20px;
  max-width: 240px;

  @media (min-width: 900px) {
    margin-top: 0;
  }
`

const TrailerDetails = styled.div`
  direction: ltr;
`

const TrailerDetailsTitle = styled.h3`
  font-size: 2em;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: none;
`

const TrailerDetailsSubtitle = styled.p`
  font-size: 1.25em;
  margin: 0;
`

const TrailerDetailsOptions = styled(Grid)`
  margin-top: 15px;
`

const TrailerFinancingContainer = styled(FinancingContainer)`
  margin: 10px 0 0;
`

const TrailerDetailsOption = styled.div`
  background-color: #fafafa;
  border: 1px solid #d7d7d7;
  padding: 15px;

  > h4 {
    font-size: 1.25em;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin: 0;
    text-align: left;
    text-transform: none;
  }

  :last-of-type {
    margin-top: 20px;
  }

  @media (min-width: 600px) {
    ${column('1/2')}

    :last-of-type {
      margin-top: 0;
    }
  }
`

const FooterModule = styled.div`
  height: 400px;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    height: 500px;
  }
`

const FooterModuleBackground = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
`

const FooterModuleOverlay = styled.div`
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media (min-width: 600px) {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 100%);
  }
`

const FooterModuleContent = styled(Content)`
  height: 100%;
  left: 50%;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
`

const FooterModuleText = styled.div`
  color: #fff;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);

  @media (min-width: 600px) {
    right: 0;
    top: 40%;
    width: 50%;
  }
`

const FooterModuleTitle = styled.h3`
  color: #fff;
  font-size: 2.5em;
  font-weight: 700;
  margin: 0;
  text-align: left;
  text-transform: none;

  @media (min-width: 600px) {
    font-size: 3em;
  }
`

const FooterModuleSubtitle = styled.p`
  color: #efefef;
  font-size: 1.25em;
  font-weight: 600;
  margin: 0 0 20px;
  text-align: left;
  text-transform: none;
`

const FooterModuleLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  query hutsonTractorPackagesQuery {
    heroImage: file(relativePath: { eq: "tractor-packages/hutson-tractor-packages.jpg" }) {
      ...FullWidthImage
    }
    moreHorsepowerImage: file(relativePath: { eq: "tractor-packages/more-horsepower.jpg" }) {
      ...FullWidthImage
    }
    trailerImage: file(relativePath: { eq: "tractor-packages/trailer.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 240)
      }
    }
    maintainerImage: file(relativePath: { eq: "tractor-packages/maintainer-tractor-package.jpg" }) {
      ...SharpImage900
    }
    groundbreakerImage: file(
      relativePath: { eq: "tractor-packages/groundbreaker-tractor-package.jpg" }
    ) {
      ...SharpImage900
    }
    gardenerImage: file(relativePath: { eq: "tractor-packages/gardener-tractor-package.jpg" }) {
      ...SharpImage900
    }
    farmHandImage: file(relativePath: { eq: "tractor-packages/farm-hand-tractor-package.jpg" }) {
      ...SharpImage900
    }
    turfTamerImage: file(
      relativePath: { eq: "tractor-packages/turf-tamer-tractor-package-v2.jpg" }
    ) {
      ...SharpImage900
    }
    snowPrepperImage: file(
      relativePath: { eq: "tractor-packages/snow-prepper-tractor-package.jpg" }
    ) {
      ...SharpImage900
    }
    # pathfinderImage: file(relativePath: { eq: "tractor-packages/pathfinder-tractor-package.jpg" }) {
    #   ...SharpImage900
    # }
  }
`

export default HutsonTractorPackagesPage
